import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { WelcomePage } from './pages/welcome/welcome.page';

const routes: Routes = [
  { path: '', component: WelcomePage },
  {
    path: 'carrello',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/carrello/carrello.module').then(m => m.CarrelloPageModule)
  },
  {
    path: 'checkout-ombrellone',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/ordini/ombrelloni/checkout-ombrellone/checkout-ombrellone.module').then(m => m.CheckoutOmbrellonePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'finalitadati',
    loadChildren: () => import('./pages/privacy/finalitadati/finalitadati.module').then(m => m.FinalitadatiPageModule)
  },

  {
    path: 'scelta-ombrellone',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/ordini/ombrelloni/scelta-ombrellone/scelta-ombrellone.module').then(m => m.SceltaOmbrellonePageModule)
  },
  {
    path: 'paypal-payment',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pagamenti/paypal-payment/paypal-payment.module').then(m => m.PayPalPaymentPageModule)
  },
  {
    path: 'pagamenti-card',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pagamenti/stripe-payment/pagamenti-card/pagamenti-card.module').then(m => m.PagamentiCardPageModule)
  },
  {
    path: 'pagamenti-card-choose',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pagamenti/stripe-payment/pagamenti-card-choose/pagamenti-card-choose.module').then(m => m.PagamentiCardChoosePageModule)
  },
  {
    path: 'pagamenti-card-edit',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pagamenti/stripe-payment/pagamenti-card-edit/pagamenti-card-edit.module').then(m => m.PagamentiCardEditPageModule)
  },
  {
    path: 'pagamenti-esito',
    loadChildren: () => import('./pages/pagamenti/pagamenti-esito/pagamenti-esito.module').then(m => m.PagamentiEsitoPageModule)
  },
  {
    path: 'profilo',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/profilo/profilo.module').then(m => m.ProfiloPageModule)
  },
  {
    path: 'recuperapassword',
    loadChildren: () => import('./pages/recuperapassword/recuperapassword.module').then(m => m.RecuperapasswordPageModule)
  },
  {
    path: 'riepilogo',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/riepilogo/riepilogo.module').then(m => m.RiepilogoPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'signup-completa',
    loadChildren: () => import('./pages/signup-completa/signup-completa.module').then(m => m.SignupCompletaPageModule)
  },
  {
    path: 'trattamentodati',
    loadChildren: () => import('./pages/privacy/trattamentodati/trattamentodati.module').then(m => m.TrattamentodatiPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'menu-slider',
    loadChildren: () => import('./pages/menu-slider/menu-slider.module').then(m => m.MenuSliderPageModule)
  },
  {
    path: 'ordini-bar',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/ordini/ordini-bar/ordini-bar.module').then(m => m.OrdiniBarPageModule)
  },
  {
    path: 'mainpage',
    loadChildren: () => import('./pages/mainpage/mainpage.module').then(m => m.MainpagePageModule)
  },
  {
    path: 'new-search-loader',
    loadChildren: () => import('./components/new-search-loader/new-search-loader.module').then(m => m.NewSearchLoaderPageModule)
  },
  {
    path: 'ristorante',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/ordini/ristorante/ristorante.module').then(m => m.RistorantePageModule)
  },
  {
    path: 'live-webcam',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/live-webcam/live-webcam.module').then(m => m.LiveWebcamPageModule)
  },
  {
    path: 'wallet-payment',
    loadChildren: () => import('./pages/pagamenti/stripe-payment/wallet-payment/wallet-payment.module').then(m => m.WalletPaymentPageModule)
  },
  {
    path: 'virtual-tour',
    loadChildren: () => import('./pages/virtual-tour/virtual-tour.module').then(m => m.VirtualTourPageModule)
  },
  {
    path: 'ecommerce',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/ecommerce/ecommerce.module').then(m => m.EcommercePageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
