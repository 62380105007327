import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from './utils/user.service';
 

@Injectable({
  providedIn: 'root'
})

  @Injectable()
  export class AuthGuardService implements CanActivate {
      constructor(private userService: UserService
          ) {
          }
        


      canActivate(): boolean {
     //   console.log(this.myService.isUserLoggedIn)
        return this.userService.isUserLoggedIn;
      }
   
  }