<ion-app class="pwa__container">
  <ion-router-outlet></ion-router-outlet>
  <ion-router-outlet *ngIf="isServerStatusDown == false; else elseBlock"></ion-router-outlet>
  <ng-template #elseBlock>
    <div class="text__center status-main-container">
      <img src="../../../assets/imgs/logo.png" /><br>
      <img src="../../../assets/imgs/status-loader.gif" /><br>
      <ion-button size="small" (click)="this.checkServerStatus()">Riprova</ion-button><br>
    </div>
  </ng-template>
</ion-app>