export const environment = {
  production: false,
  googleAuthClientId: "363417740071-5k4ukk7vjtku4982hobqut9gceoc144i.apps.googleusercontent.com",
  firebaseConfig: {
    apiKey: 'AIzaSyDGZbLIq1t19tjtGQxuhbQsyNoDJKI33J8',
    authDomain: 'sunset-beach-app-push.firebaseapp.com',
    databaseURL: 'https://sunset-beach-app-push.firebaseio.com',
    projectId: 'sunset-beach-app-push',
    storageBucket: 'sunset-beach-app-push.appspot.com',
    messagingSenderId: '363417740071',
    appId: '1:363417740071:web:71a958f711e4d79d7cff02',
    measurementId: 'G-KBCZ4P8L0C'
  }
};

export const STRIPE_KEY = 'pk_test_saXo7SfdATSeSBNpQiN13NKg00B9H3i8zY';
export const emailPattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const applicationServer = 'https://www.sunsetbeachapp.it/sunset-ws';

export const serviziRest = {


  'firebaseAccess': applicationServer + '/rest/public/users/firebaseAccess',
  'appleSignInAccess': applicationServer + '/rest/public/users/appleAccess',
  'isBarSpiaggiaAttivo': applicationServer + '/rest/public/users/getParametroInt?idParametro=1010',
  'isVirtualTourAttivo': applicationServer + '/rest/public/users/getParametroInt?idParametro=1036',
  'getVirtualTourUrl': applicationServer + '/rest/parametro/getParametroString?idParametro=1037',
  'getCondizioniVendita': applicationServer + '/rest/parametro/getParametroString?idParametro=1038',
  'numeroPagineMenu': applicationServer + '/rest/public/users/getParametroInt?idParametro=1020',
  'maxOmbrelloniPrenotabiliPerUtente': applicationServer + '/rest/public/users/getParametroInt?idParametro=2',
  'isRistoranteAttivo': applicationServer + '/rest/public/users/getParametroInt?idParametro=1019',
  'isSpiaggiaAperta': applicationServer + '/rest/public/users/getParametroInt?idParametro=1039',
  'isMezzeGiornateDisponibili': applicationServer + '/rest/public/users/getParametroInt?idParametro=1042',
  'checkAppInManutenzione': applicationServer + '/rest/public/users/getParametroInt?idParametro=1005',
  'checkAppVersion': applicationServer + '/rest/public/version/checkVersion',
  'isFbLoginIosAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1018',
  'isShowPopOverRistoranteAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1027',
  'isShowPopOverOmbrelloneAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1028',
  'isFbLoginAndroidAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1016',
  'isFbLoginWebAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1017',
  'isEliminaAccountAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1026',
  'isLoginGoogleiOSAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1014',
  'isLoginGoogleAndroidAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1013',
  'isLoginGoogleWebAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1015',
  'isLoginAppleiOSAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1021',
  'isApplePayAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1022',
  'isGooglePayAvailable': applicationServer + '/rest/public/users/getParametroInt?idParametro=1023',

  'getPrezziServizi': applicationServer + '/rest/servizioOmbrellone/getServizi',
  'getLimiteOrarioPrenotazione': applicationServer + '/rest/public/users/getParametroInt?idParametro=21',
  'getMaxGgAbbonamento': applicationServer + '/rest/parametro/getParametroInt?idParametro=12',
  'getPopupDisponibile': applicationServer + '/rest/parametro/getParametroInt?idParametro=1006',
  'getPopupGenericoDisponibile': applicationServer + '/rest/parametro/getParametroInt?idParametro=1034',
  'offsetPopupGenericoInformativo': applicationServer + '/rest/parametro/getParametroInt?idParametro=1035',
  'getNumeroMaxSdraio': applicationServer + '/rest/parametro/getParametroInt?idParametro=1007',
  'getNumeroMinSdraio': applicationServer + '/rest/parametro/getParametroInt?idParametro=1033',
  'getNumeroMaxCuscini': applicationServer + '/rest/parametro/getParametroInt?idParametro=1008',
  'getNumeroMinCuscini': applicationServer + '/rest/parametro/getParametroInt?idParametro=1009',
  'isRitiroSpiaggiaDisponibile': applicationServer + '/rest/parametro/getParametroInt?idParametro=1011',
  'isConsegnaOmbrelloneDisponibile': applicationServer + '/rest/parametro/getParametroInt?idParametro=1012',
  'isLiveStreamingAvailableiOS': applicationServer + '/rest/parametro/getParametroInt?idParametro=27',
  'isLiveStreamingAvailableAndroid': applicationServer + '/rest/parametro/getParametroInt?idParametro=29',
  'getLiveStreamingData': applicationServer + '/rest/parametro/getParametroString?idParametro=28',
  'getLiveStreamingM3u': applicationServer + '/rest/parametro/getParametroString?idParametro=30',
  'getImportoMinimoOrdinebar': applicationServer + '/rest/parametro/getParametroInt?idParametro=1003',
  'valutaOrdine': applicationServer + '/rest/ordine/valutaOrdine',
  'getPrezziOmbrelloni': applicationServer + '/rest/prezzoOmbrellone/getPrezziQuotidiani',


  'getUltimaPrevisione': applicationServer + '/rest/public/previsioneMeteo/getUltimaPrevisione',
  'getInfoStandard': applicationServer + '/rest/ordine/getOrdini',
  'getListaBar': applicationServer + '/rest/bar/getListaBar',
  'getListaBarPerCategoria': applicationServer + '/rest/bar/getListaBarPerCategoria',
  'getMaxElemento': applicationServer + '/rest/bar/getMaxElemento',
  'getDisponibilitaRistorante': applicationServer + '/rest/ristorante/getDisponibilitaRistorante?',
  'bloccaOmbrellone': applicationServer + '/rest/ombrellone/bloccaOmbrellone',
  'sbloccaOmbrellone': applicationServer + '/rest/ombrellone/sbloccaOmbrellone',
  'getPrezziOmbrellone': applicationServer + '/rest/ombrellone/getPrezziOmbrellone?idOmbrellone=',
  'loginManuale': applicationServer + '/rest/public/users/mLogin',
  'loginFb': applicationServer + '/rest/public/users/fbAccess',
  'registrazioneManuale': applicationServer + '/rest/public/users/mRegister',
  'getUtente': applicationServer + '/rest/utente/getUtente',
  'getListaUtenti': applicationServer + '/rest/ges/utente/getAllUtente',
  'deleteUtente': applicationServer + '/rest/utente/deleteUtente',
  'deletePrenotazioneOmbr': applicationServer + '/rest/ges/prenotazioneOmbrellone/deletePrenotazioneOmbrellone',
  'deletePrenotazioneRist': applicationServer + '/rest/prenotazioneRistorante/deletePrenotazioneRistorante',
  'updateUtente': applicationServer + '/rest/ges/utente/updateUtente',
  'getAllElementoBar': applicationServer + '/rest/ges/categoriaBar/getAllCategoriaBar',
  'deleteElementoBar': applicationServer + '/rest/ges/elementoBar/deleteElementoBar',
  'updateElementoBar': applicationServer + '/rest/ges/elementoBar/updateElementoBar',
  'creaElementoBar': applicationServer + '/rest/ges/elementoBar/createElementoBar',
  'getDisponibilitaMassima': applicationServer + '/rest/ges/disponibilitaRistorante/getDisponibilitaMassima',
  'updateDisponibilitaRistorante': applicationServer + '/rest/ges/disponibilitaRistorante/updateDisponibilitaRistorante',
  'setUtente': applicationServer + '/rest/utente/updateUtente',
  'getOmbrelloni': applicationServer + '/rest/ombrellone/getOmbrelloniPeriodo?dataInizio=',
  'getOmbrelloniPeriodo': applicationServer + '/rest/ombrellone/getOmbrelloniPeriodo?dataInizio=',

  'getNotizieDisponibili': applicationServer + '/rest/notizia/getNotizieDisponibili',
  'getNotiziePublic': applicationServer + '/rest/public/notizia/getNotizieDisponibili',
  'recuperaPassword': applicationServer + '/rest/public/users/requestPassReset',

  'getDefaultCard': applicationServer + '/rest/stripe/getDefaultCardinfo',
  'updateDefaultCard': applicationServer + '/rest/stripe/updateDefaultCard',
  'updateDefaultCard2': applicationServer + '/rest/stripe/updateDefaultCard',
  'getCardsList': applicationServer + '/rest/stripe/getCardsList',
  'removeCard': applicationServer + '/rest/stripe/removeCard',

  'braintreeGenToken': applicationServer + '/rest/braintree/getClientToken',
  'braintreePay': applicationServer + '/rest/ordine/payBraintree',
  'payPaypal': applicationServer + '/rest/ordine/payPaypal',
  'completaOrdinePaypal': applicationServer + '/rest/ordine/completaOrdinePaypal',

  'stripePayOld': applicationServer + '/rest/ordine/pay',
  'stripePay': applicationServer + '/rest/ordine/payStripe',
  'stripeRetrieveSetupClientSecret': applicationServer + '/rest/ordine/generateStripeSetupIntent',
  'stripeRetrieveClientSecret': applicationServer + '/rest/ordine/generateStripeIntent',
  'generateStripeIntentWallet': applicationServer + '/rest/ordine/generateStripeIntentWallet',
  'stripePayWithIntent': applicationServer + '/rest/ordine/payStripeWithIntent',
  'completaOrdine': applicationServer + '/rest/ordine/completaOrdine',

  'iniziaOrdine': applicationServer + '/rest/ordine/iniziaOrdine',
  'getUrlStreaming': applicationServer + '/rest/parametro/getUrlStreaming',

  'isPaypalDisponibile': applicationServer + '/rest/parametro/isPaypalAvailable',
  'pagaSoloContanti': applicationServer + '/rest/parametro/getPagaSoloContanti',
  'pagaAncheContanti': applicationServer + '/rest/parametro/getPagaAncheContanti',
  'calcolaInizioStagione': applicationServer + '/rest/public/users/getParametroString?idParametro=16',
  'calcolaFineStagione': applicationServer + '/rest/public/users/getParametroString?idParametro=17',
  'calcolaInizioStagioneRistorante': applicationServer + '/rest/public/users/getParametroString?idParametro=18',
  'calcolaFineStagioneRistorante': applicationServer + '/rest/public/users/getParametroString?idParametro=19',
  'updateOrdineConcluso': applicationServer + '/rest/ordine/updateOrdineConcluso',
  'aggiornaTokenDevice': applicationServer + '/rest/tokenDevice/aggiornaTokenDevice',
  'getSlotFasciaOrariaPranzo': applicationServer + '/rest/parametro/getParametroString?idParametro=1024',
  'getSlotFasciaOrariaCena': applicationServer + '/rest/parametro/getParametroString?idParametro=1025',
  'getSlotPopOverRistorante': applicationServer + '/rest/parametro/getParametroString?idParametro=1029',
  'getSlotPopOverOmbrellone': applicationServer + '/rest/parametro/getParametroString?idParametro=1030',
}