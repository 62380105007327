import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class DatiGlobaliService {

  carrelloDataGlobale: any;
  totale: any;
  offsetPopuperGenerico: number = 3;
  idOrdine: any;
  idTurno: any;
  ombrelloneData: any;
  idTipoOmbrellone: any;
  idOmbrellone: any;
  labelOmbrellone: any;
  esito: any;
  messaggio: any;
  lastUpdateTime: any;
  currentTime: any;
  ristoranteDataSceltaPopOver: any;

  constructor() {
    this.lastUpdateTime = new Date();
    this.currentTime = new Date();
  }

  checkLastUpdateTime(): boolean {
    this.currentTime = new Date();
    var differenceInTime = Math.floor((this.currentTime.getTime() - this.lastUpdateTime.getTime()) / 1000);
    if (differenceInTime > 60) {
      this.lastUpdateTime = new Date();
      return true;
    }
    return false;
  }

  generateRandomId(lunghezza) {
    return '?fdgNoCache=' + this.makeRandomId(lunghezza);
  }

  public makeRandomId(length): string {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
}
