import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { StorageService } from './storage-service.service';
import { UserService } from './utils/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingserviceService {

  paginaProvenienza: string;
  paginaPrecedente: string;

  constructor(
    private router: Router,
    private storage: StorageService,
    private userService: UserService
  ) { }

  goTo(destination, currentPage, daDovechiamo) {
    if (destination !== currentPage) {
      this.router.navigate(['/' + destination]);
    }
  }




  login() {
    /*     console.log('login'); */
    this.goTo('welcome', this.paginaProvenienza, 'da routing service login');
  }


  logout() {
    if (this.userService.isUserLoggedInGoogle) {
      try {

        GoogleAuth.signOut();
      } catch (err) {
        console.log(err);
      }
    }

    if (this.userService.isUserLoggedInFacebook) {
      try {

        FacebookLogin.logout();
      } catch (err) {
        console.log(err);
      }
    }

    this.userService.isUserLoggedInApple = false;
    this.userService.isUserLoggedInGoogle = false;
    this.userService.isUserLoggedIn = false;
    this.paginaProvenienza = '';
    this.storage.set('locale_lang', '');
    this.storage.set('showModale', '');
    this.storage.set('showModaleGenerica', '');
    this.storage.set('dataCookieGenerico', '');
    this.storage.set('token', '');

    this.storage.set('username', '');


    this.goTo('welcome', this.paginaProvenienza, 'da routing service logout');

  }

}