import { Injectable } from '@angular/core';
import { SpiaggiaService } from '../../utils/spiaggia.service';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { RoutingserviceService } from '../../routingservice.service';
import { HttprequestService } from '../../httprequest.service';
import { StorageService } from '../../storage-service.service';
import { AlertService } from '../../alert-service.service';
import { UserService } from '../../utils/user.service';

@Injectable({
  providedIn: 'root'
})
export class FbloginService {

  constructor(
    private myService: SpiaggiaService,
    private http: HttprequestService,
    private storage: StorageService,
    private userService: UserService,
    private alertService: AlertService,
    private routingService: RoutingserviceService

  ) {
  }


  async fbLogin() {
    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
    const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });

    /*         'https://graph.facebook.com/me?fields=name,email,picture.width(200).height(200)&access_token=' + result.accessToken.token).subscribe((data) => { */
    if (result && result.accessToken) {
      this.http.doGet('https://graph.facebook.com/me?fields=name,email,picture.width(200).height(200)&access_token=' + result.accessToken.token).subscribe(response => {
        let data = response;
        this.loginFacebook(result.accessToken, data.email);
      });
    }

  }

  loginFacebook(res, email) {
    //  this.generateHttpHeaders();
    this.myService.mostraLoader();

    let postData = {
      "utente": {
      },
      "credentials": {
        "identifier": res.userId,
        "secret": res.token
      }

    }

    this.http.doPostApplicationTextAll(this.myService.serviziRest.loginFb, postData)
      .subscribe(res => {

        this.myService.tokenValue = res;
        this.userService.isUserLoggedIn = true;
        this.userService.isUserLoggedInFacebook = true;
        this.storage.set('token', res);
        this.storage.set('username', email);
        this.getDatiUtenteFb(email);
        this.myService.loading.dismiss();
      }, error => {
        this.myService.loading.dismiss();
        if (error.status == 0) {
          this.alertService.alertAddressError();
        } else if (error.status == 400 || error.status == 401 || error.status == 403) {
          this.alertService.alertWrongCredentials();
        } else {
          this.alertService.alertGenericError();
        }
      });

  }

  getDatiUtenteFb(email) {
    this.userService.getDatiUtente(email, 'facebookLogin');

  }


}
