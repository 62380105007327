import { Injectable } from '@angular/core';
import { SpiaggiaService } from './spiaggia.service';
import { serviziRest } from 'src/environments/environment';
import { HttprequestService } from '../httprequest.service';
import { AlertService } from '../alert-service.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  datiUtente = { "password": "", "email": "", "nome": "", "cognome": "", "telefono": "" };
  serviziRest: any;
  hasStripeToken: boolean = false;
  isUserLoggedIn: boolean;
  isUserLoggedInGoogle: boolean = false;
  isUserLoggedInApple: boolean = false;
  isUserLoggedInFacebook: boolean = false;
  idTokenGoogle: any;
  idTokenApple: any;

  constructor(
    private spiaggiaService: SpiaggiaService,
    private http: HttprequestService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.serviziRest = serviziRest;

  }

  getDatiUtente(email, provenienza) {

    let postData = {
      "email": email

    };

    this.http.doPostApplicationJsonBearer(this.serviziRest.getUtente, postData, this.spiaggiaService.tokenValue)
      .subscribe(res => {
        res.stripeToken == 1 ? this.hasStripeToken = true : false;
        this.datiUtente.nome = res.nome;
        this.datiUtente.cognome = res.cognome;
        this.datiUtente.telefono = res.telefono;
        this.datiUtente.email = email;

        if (this.datiUtente.nome == null || this.datiUtente.nome == '' ||
          this.datiUtente.cognome == null || this.datiUtente.cognome == '' ||
          this.datiUtente.telefono == null || this.datiUtente.telefono == ''
        ) {

          if (provenienza == '') {
            this.isUserLoggedIn = true;
          } else if (provenienza == 'facebookLogin') {
            this.isUserLoggedInFacebook = true;
            this.isUserLoggedInGoogle = false;
            this.isUserLoggedInApple = false;
          } else if (provenienza == 'googleSignIn') {
            this.isUserLoggedInFacebook = false;
            this.isUserLoggedInGoogle = true;
            this.isUserLoggedInApple = false;
          } else if (provenienza == 'appleSignIn') {
            this.isUserLoggedInFacebook = false;
            this.isUserLoggedInGoogle = false;
            this.isUserLoggedInApple = true;
          }
          else {
            this.isUserLoggedIn = true;
            this.isUserLoggedInFacebook = false;
            this.isUserLoggedInGoogle = false;
            this.isUserLoggedInApple = false;
          }

          this.router.navigate(['/signup-completa']);
        } else {
          this.isUserLoggedIn = true;
          this.router.navigate(['/mainpage']);
        }

      }, error => {
        if (error.status == 0) {
          this.alertService.alertAddressError();
        } else if (error.status == 400 || error.status == 401 || error.status == 403) {
          this.alertService.alertWrongCredentials();
        } else {
          this.alertService.alertGenericError();
        }
      });


  }



  setDatiUtente() {
    this.spiaggiaService.updateDatiLoader();
    let postData = {
      "nome": this.datiUtente.nome,
      "cognome": this.datiUtente.cognome,
      "telefono": this.datiUtente.telefono,
      "image": ''

    };


    this.http.doPostApplicationJsonBearer(this.serviziRest.setUtente, postData, this.spiaggiaService.tokenValue)
      .subscribe(() => {
        this.spiaggiaService.stoppaLoader();
        this.getDatiUtente(this.datiUtente.email, '');

      }, error => {
        this.spiaggiaService.stoppaLoader();
        // console.log(error.status);
        if (error.status == 0) {
          this.alertService.alertAddressError();
        } else if (error.status == 400 || error.status == 401 || error.status == 403) {
          this.alertService.alertWrongCredentials();
        } else {
          this.alertService.alertGenericError();
        }

        console.log(error);
      });


  }



}
