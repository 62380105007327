import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { LangProvider } from './services/lang/lang';
import { SpiaggiaService } from './services/utils/spiaggia.service';
import { AuthGuardService } from './services/auth-guard.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { HttprequestService } from './services/httprequest.service';
import { StorageService } from './services/storage-service.service';
import { serviziRest } from 'src/environments/environment';
import { AlertService } from './services/alert-service.service';
import { UserService } from './services/utils/user.service';
import { OmbrelloneService } from './services/utils/ombrellone.service';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  isServerStatusDown: boolean = false;
  tokenValue: boolean = false; //qui verifico il token dell'utente: se ok, manda ai servizi altrimenti alla login
  platformOs: string = "";
  versionNr: string = "3.1.012"; // release attuale 3.1.010
  alertAppObsoletaLabel = "";
  alertAppObsoletaMessLabel = "";

  alertAppInAggiornamentoLabel = "";
  alertAppInAggiornamentoMessLabel = "";

  constructor(
    private platform: Platform,
    private router: Router,
    private lingua: LangProvider,
    private myService: SpiaggiaService,
    private userService: UserService,
    private httpService: HttprequestService,
    private alertService: AlertService,
    private storage: StorageService,
    private authGuardService: AuthGuardService,
    private ombrelloneService: OmbrelloneService,
  ) {
    this.checkServerStatus();
  }

  checkServerStatus() {
    this.isServerStatusDown = false;
    this.platform.ready().then(() => {




      this.httpService.doGetTimeout(serviziRest.checkAppInManutenzione).subscribe(res => {
        SplashScreen.hide();
        if (res != null && res != undefined) {
          if (res == 1) {
            this.myService.appInManutenzione = true;
            this.isServerStatusDown = true;
            this.alertService.alertAppInManutenzione();
            // this.logout();
          } else {
            this.myService.appInManutenzione = false;
            this.isServerStatusDown = false;
            this.initializeApp();
          }

        }

      }, error => {
        console.log(error);
      });
    });
  }

  initializeApp() {
    this.checkFirstSteps();
  }


  checkFirstSteps() {
    this.storage.get('locale_lang').then((res => {
      if (res != undefined && res != null && res != '') {
        this.lingua.languageSelected = res;
      }
      else {
        this.storage.set('locale_lang', 'IT');
        this.lingua.languageSelected = 'IT';
      }

      this.checkAppPlatform();
      this.checkLastStepsAndStart();
      this.checkFunzioniDisponibili();
    }));
  }

  checkFunzioniDisponibili() {
    this.myService.checkSocialLogin();
    this.myService.getLimiteOrarioPrenotazione();
  }



  checkAppPlatform() {
    this.myService.isPlatformWeb = true;
    if (this.platform.is('android')) {
      this.platformOs = 'android';
      this.myService.isPlatformAndroid = true;
      this.myService.isPlatformIos = false;
      /*        this.myService.isPlatformWeb = false; */
    } else if (this.platform.is('ios')) {
      this.myService.isPlatformAndroid = false;
      this.myService.isPlatformIos = true;
      this.platformOs = 'ios';
      /*        this.myService.isPlatformWeb = false; */
    } else if (this.platform.is('desktop')) {
      this.myService.isPlatformAndroid = false;
      this.myService.isPlatformIos = false;
      this.myService.isPlatformWeb = true;
      this.platformOs = 'other';
    } else {
      this.platformOs = 'other';
      this.myService.isPlatformWeb = true;
    }
  }
  checkLastStepsAndStart() {

    this.myService.isVersionObsoleta = false;
    this.storage.get('token').then((res => {

      if (res != undefined && res != null && res != '') {
        this.userService.isUserLoggedIn = true;
        this.storage.get('username').then((res => {
          this.userService.getDatiUtente(res, '');
        }));
        this.myService.checkPopOver();
        this.router.navigate(['/mainpage']);
        this.authGuardService.canActivate();
      }
      else {
        this.userService.isUserLoggedIn = false;
        this.router.navigate(['/']);
        this.authGuardService.canActivate();
      }
    }));
  }


  logout() {

    this.userService.isUserLoggedIn = false;
    this.storage.set('token', '');
    this.storage.set('username', '');
    this.storage.set('showModale', '');
    this.storage.set('showModaleGenerica', '');
    this.storage.set('dataCookieGenerico', '');
    this.router.navigate(['/welcome']);
  }



}
