<div class="ion-padding">

  <form novalidate [formGroup]="loginForm">

    <div class="text__container">
      <ion-label floating class="">Email</ion-label>
      <ion-input #myInput type="email" formControlName="email" aria-label="email-input"
        [class.error1]="!loginForm.controls.email.valid && loginForm.controls.email.dirty"></ion-input>

      <div class="error__message"
        *ngIf="( loginForm.get('email').hasError('minlength') || loginForm.get('email').hasError('pattern') ||loginForm.get('email').hasError('required') ) && loginForm.get('email').touched">
        <div class="text__red error"
          *ngIf="loginForm.get('email').hasError('required') && loginForm.get('email').touched">
          {{this.loginLabelService.insertEmail}}
        </div>
        <div class="text__red error"
          *ngIf="loginForm.get('email').hasError('pattern') && loginForm.get('email').touched">
          {{this.loginLabelService.invalidEmail}}
        </div>
      </div>
    </div>



    <div class="text__container">
      <ion-label class="" floating>Password</ion-label>
      <div class="password__container">
        <ion-input type="password" formControlName="password" #input aria-label="password-input"
          [class.error1]="!loginForm.controls.password.valid && loginForm.controls.password.dirty">
        </ion-input>
        <ion-icon #icon (click)="showPassword(input, icon)" class="icona__mostra__password" name="eye-outline">
        </ion-icon>
      </div>

      <div class="error__message"
        *ngIf="( loginForm.get('password').hasError('minlength') || loginForm.get('password').hasError('maxlength') ||loginForm.get('password').hasError('required') ) && loginForm.get('password').touched">
        <div class="text__red error"
          *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">
          {{this.loginLabelService.insertPass}}
        </div>
        <div class="text__red error"
          *ngIf="loginForm.get('password').hasError('minlength') && loginForm.get('password').touched">
          {{this.loginLabelService.lunghMinSeiCaratteri}}
        </div>
        <div class="text__red error"
          *ngIf="loginForm.get('password').hasError('maxlength') && loginForm.get('password').touched">
          {{this.loginLabelService.lunghMaxDodiciCaratteri}}
        </div>

      </div>
    </div>
    <div class="text__right " style="padding: 0 5px; margin-top: -16px;">
      <a (click)="recuperaPassword()" id="password__dimenticata">
        <p class="recuperaPass ion-padding drop__shadow__white__text"> {{this.loginLabelService.passDimenticata}} </p>

      </a>
    </div>

    <ion-row>
      <ion-col center>
        <ion-button size="small" class="welcome-page__btn_text text-center " type="submit" expand="full" color="primary"
          (click)="loginManuale();" [disabled]="loginForm.invalid">Login</ion-button>
      </ion-col>
    </ion-row>

  </form>
</div>