import { Injectable } from "@angular/core";
import { LangProvider } from "src/app/services/lang/lang";

@Injectable({
  providedIn: 'root'
})

export class LoginLabelService {
  proseguiGuestOppureLabel = "";
  appUpdateLabel = "";
  passDimenticata = "";
  faceLogin = "";
  registrazione = "";
  insertEmail = "";
  insertPass = "";
  invalidEmail = "";
  lunghMinSeiCaratteri = "";
  lunghMaxDodiciCaratteri = "";
  proseguiGuestLabel = "";
  alertLetturaDatiLabel = "";
  constructor(
    public lingua: LangProvider,
  ) {
    this.setLingua();
  }


  setLingua() {
    if (this.lingua.languageSelected == 'IT') {
      this.appUpdateLabel = this.lingua.appUpdateLabel_IT;
      this.proseguiGuestOppureLabel = this.lingua.proseguiGuestOppure_IT;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_IT;
      this.proseguiGuestLabel = this.lingua.proseguiGuestLabel_IT;
      this.passDimenticata = this.lingua.welcome_passDimenticata_IT;
      this.faceLogin = this.lingua.welcome_fbLogin_IT;
      this.registrazione = this.lingua.welcome_registrati_IT;
      this.insertEmail = this.lingua.welcome_inserireMail_IT;
      this.invalidEmail = this.lingua.welcome_emailNonValida_IT;
      this.insertPass = this.lingua.welcome_inserirePassword_IT;
      this.lunghMinSeiCaratteri = this.lingua.lunghMinSeiCar_IT;
      this.lunghMaxDodiciCaratteri = this.lingua.lunghMaxDodCar_IT;

    } else if (this.lingua.languageSelected == 'EN') {
      this.proseguiGuestOppureLabel = this.lingua.proseguiGuestOppure_EN;
      this.appUpdateLabel = this.lingua.appUpdateLabel_EN;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_EN;
      this.proseguiGuestLabel = this.lingua.proseguiGuestLabel_EN;
      this.passDimenticata = this.lingua.welcome_passDimenticata_EN;
      this.faceLogin = this.lingua.welcome_fbLogin_EN;
      this.registrazione = this.lingua.welcome_registrati_EN;
      this.insertPass = this.lingua.welcome_inserirePassword_EN;
      this.insertEmail = this.lingua.welcome_inserireMail_EN;
      this.invalidEmail = this.lingua.welcome_emailNonValida_EN;
      this.lunghMinSeiCaratteri = this.lingua.lunghMinSeiCar_EN;
      this.lunghMaxDodiciCaratteri = this.lingua.lunghMaxDodCar_EN;

    } else if (this.lingua.languageSelected == 'FR') {
      this.proseguiGuestOppureLabel = this.lingua.proseguiGuestOppure_FR;
      this.appUpdateLabel = this.lingua.appUpdateLabel_FR;
      this.proseguiGuestLabel = this.lingua.proseguiGuestLabel_FR;
      this.passDimenticata = this.lingua.welcome_passDimenticata_FR;
      this.faceLogin = this.lingua.welcome_fbLogin_FR;
      this.registrazione = this.lingua.welcome_registrati_FR;
      this.insertPass = this.lingua.welcome_inserirePassword_FR;
      this.insertEmail = this.lingua.welcome_inserireMail_FR;
      this.invalidEmail = this.lingua.welcome_emailNonValida_FR;
      this.lunghMinSeiCaratteri = this.lingua.lunghMinSeiCar_FR;
      this.lunghMaxDodiciCaratteri = this.lingua.lunghMaxDodCar_FR;

    }
  }


}

