import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { LangProvider } from "../lang/lang";
import { HttprequestService } from "../httprequest.service";
import { serviziRest } from "src/environments/environment";
import { StorageService } from "../storage-service.service";
import { DatiGlobaliService } from "./dati-globali.service";

@Injectable({ providedIn: "root" })
export class SpiaggiaService {
  orarioLimitePrenotazioneOmbr: number = 11;
  maxGgAbbonamentoPrenotabili: number = 12;
  numMaxOmbrelloniPrenotabili: number = 10;
  isLoading: boolean = false;
  tipoPrenotazione: string = "";
  provenienzaPopOverRistorante: boolean = false;
  provenienzaPopOverOmbrelloni: boolean = false;
  showPopOverRistorante: boolean = false;
  showPopOverOmbrelloni: boolean = false;
  liveStreamingUrlAndroid: any;
  liveStreamingUrlIOS: any;
  liveStreamingData: string = ""; //fallback
  liveStreamingM3u: string = ""; //fallback
  isLiveStreamingDisponibileiOS: boolean = false;
  isLiveStreamingDisponibileAndroid: boolean = false;
  isVTAttivo: boolean = false;
  isEliminaAccountAvailable: boolean = false;
  proseguiGuestLabel: string = "";
  registrazione: string = "";
  appInManutenzione: boolean;
  faceLogin: string = "";
  provenienzaPage: string = "";
  virtualTourUrl: any;
  menuUrl: any = "https://www.sunset-palmi.it/pages/menu-app.html";
  appUpdateLabel: any;
  riprovaLabel: any;
  numMaxSdraio: number = 2;
  numMaxCuscini: number = 6;
  numMinCuscini: number = 4;
  numMinSdraio: number = 2;
  arrayNumMaxCuscini: any;
  arrayNumMaxSdraio: any;
  errorMessageStripe: string = "";
  isSpiaggiaPrenotabile: boolean = true;
  isMezzeGiornateDisp: boolean = false;
  condizioniVendita: string = "";

  riepilogoOrdine = {};

  avvisoPagamentoContanti: boolean = false;

  serviziRest: any;
  contaIntervalCheckoutPage: any;
  result = [];

  isPagamentiOnlineAbilitato: boolean = false;
  isPagamentiContantiAbilitato: boolean = false;
  isPaypalDisponibile: boolean = false;
  observableVar: Subscription;

  isPlatformAndroid: boolean = false;
  isPlatformIos: boolean = false;
  isPlatformWeb: boolean = false;
  isLoginFbIosAvailable: boolean = false;
  isLoginFbAndroidAvailable: boolean = false;
  isLoginGoogleiOSAvailable: boolean = false;
  isLoginGoogleAndroidAvailable: boolean = false;
  isLoginGoogleWebAvailable: boolean = false;
  isLoginFbWebAvailable: boolean = false;
  isLoginAppleiOSAvailable: boolean = true;

  isVersionObsoleta: boolean = false;

  loading: any; // variabile per il loader che appare a video

  tokenValue: any;

  idOmbrelloneBloccato: any;
  dataOmbrelloneBloccato: any;

  alertPrenotaOmbrelloneLabel = "";

  alertTempoScadutoLabel = "";
  alertUtentePassErratiLabel = "";
  alertUtentePassRiprovareLabel = "";

  alertLetturaDatiLabel = "";
  alertPagamentoInCorsoLabel = "";
  alertSalvataggioInCorsoLabel = "";
  alertErrorLabel = "";
  alertErrorMessageLabel = "";

  infoLabel = "";
  ordiniLabel = "";

  bookLabel = "";

  disponibilitaLabel = "";
  menuSlides = [];
  constructor(
    public lingua: LangProvider,
    private http: HttprequestService,
    private sanitizer: DomSanitizer,
    public loadingCtrl: LoadingController,
    private storage: StorageService,
    private datiGlobali: DatiGlobaliService
  ) /*  public app: App */ {
    this.setLingua();
    this.serviziRest = serviziRest;

    this.idOmbrelloneBloccato = "";
    this.dataOmbrelloneBloccato = "";
    this.storage.get("token").then((res) => {
      this.tokenValue = res;
      this.getNumeroPagineMenu();
    });
  }

  getLimiteOrarioPrenotazione() {
    this.http.doGet(serviziRest.getLimiteOrarioPrenotazione).subscribe(
      (res) => {
        if (res != null && res != undefined) {
          this.orarioLimitePrenotazioneOmbr = res;
        } else {
          this.orarioLimitePrenotazioneOmbr = 11;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getNumeroPagineMenu() {
    this.menuSlides = [];
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.numeroPagineMenu,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          //console.log(res);
          for (let i = 0; i < res; i++) {
            this.menuSlides.push({
              title: "",
              description: "",
              image: `https://www.sunset-palmi.it/app/menu/menu0${i}.jpg${this.datiGlobali.generateRandomId(
                6
              )}`,
            });
          }
        },
        (error) => {
          console.log(error.status);
        }
      );
  }

  getCondizioniVendita() {
    this.http
      .doGetTextBearer(this.serviziRest.getCondizioniVendita, this.tokenValue)
      .subscribe(
        (res) => {
          let condizioniDiVendita = res.split("|");
          this.condizioniVendita =
            this.lingua.languageSelected == "IT"
              ? condizioniDiVendita[0]
              : this.lingua.languageSelected == "EN"
                ? condizioniDiVendita[1]
                : condizioniDiVendita[2];
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getVirtualTourUrl() {
    this.http
      .doGetTextBearer(this.serviziRest.getVirtualTourUrl, this.tokenValue)
      .subscribe(
        (res) => {
          this.virtualTourUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(res);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  calculateMenuUrl() {
    this.menuUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.sunset-palmi.it/pages/menu-app.html' + this.datiGlobali.generateRandomId(5));
  }

  isSpiaggiaAperta() {
    this.http.doGet(serviziRest.isSpiaggiaAperta).subscribe(
      (res) => {
        this.isSpiaggiaPrenotabile = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  isMezzeGiornateDisponibili() {
    this.http.doGet(serviziRest.isMezzeGiornateDisponibili).subscribe(
      (res) => {
        this.isMezzeGiornateDisp = res == 1 ? true : false;
        /*   console.log(`isMezzeGiornateDisp: ${this.isMezzeGiornateDisp}`); */
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setLinguaTabs() {
    if (this.lingua.languageSelected == "IT") {
      this.infoLabel = this.lingua.infoLabel_IT;
      this.bookLabel = this.lingua.prenotaTabBar_IT;
      this.ordiniLabel = this.lingua.listaOrdiniLabel_IT;
    } else if (this.lingua.languageSelected == "EN") {
      this.infoLabel = this.lingua.infoLabel_EN;
      this.ordiniLabel = this.lingua.listaOrdiniLabel_EN;
      this.bookLabel = this.lingua.prenotaTabBar_EN;
    } else if (this.lingua.languageSelected == "FR") {
      this.infoLabel = this.lingua.infoLabel_FR;
      this.ordiniLabel = this.lingua.listaOrdiniLabel_FR;
      this.bookLabel = this.lingua.prenotaTabBar_FR;
    } else if (this.lingua.languageSelected == "NL") {
      this.infoLabel = this.lingua.infoLabel_NL;
      this.ordiniLabel = this.lingua.listaOrdiniLabel_NL;
      this.bookLabel = this.lingua.prenotaTabBar_NL;
    }
  }
  getTime(currentTime) {
    return (
      (currentTime.getDate() <= 9
        ? "0" + currentTime.getDate()
        : currentTime.getDate()) +
      "/" +
      (currentTime.getMonth() + 1 <= 9
        ? "0" + (currentTime.getMonth() + 1)
        : currentTime.getMonth() + 1) +
      "/" +
      currentTime.getFullYear() +
      ", " +
      (currentTime.getHours() <= 9
        ? "0" + currentTime.getHours()
        : currentTime.getHours()) +
      ":" +
      (currentTime.getMinutes() <= 9
        ? "0" + currentTime.getMinutes()
        : currentTime.getMinutes())
    );
  }

  getNumeroCuscini() {
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.getNumeroMaxCuscini,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.numMaxCuscini = res;
          this.arrayNumMaxCuscini = new Array(this.numMaxCuscini).fill(
            this.numMaxCuscini
          );
        },
        (error) => {
          console.log(error.status);
        }
      );
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.getNumeroMinCuscini,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.numMinCuscini = res;
        },
        (error) => {
          console.log(error.status);
        }
      );
  }

  getNumeroSdraio() {
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.getNumeroMaxSdraio,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.numMaxSdraio = res;
          this.arrayNumMaxSdraio = new Array(this.numMaxSdraio).fill(
            this.numMaxSdraio
          );
        },
        (error) => {
          console.log(error.status);
        }
      );
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.getNumeroMinSdraio,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.numMinSdraio = res;
        },
        (error) => {
          console.log(error.status);
        }
      );
  }

  isVirtualTourAttivo() {
    this.http.doGet(serviziRest.isVirtualTourAttivo).subscribe(
      (res) => {
        this.isVTAttivo = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getIsLiveStreamingDisponibile() {
    if (this.isPlatformIos) {
      this.http
        .doGetApplicationJsonBearer(
          this.serviziRest.isLiveStreamingAvailableiOS,
          this.tokenValue
        )
        .subscribe(
          (res) => {
            this.isLiveStreamingDisponibileiOS = res == 1 ? true : false;
            this.getLiveStreamingData();
          },
          (error) => {
            console.log(error);
          }
        );
    }
    if (this.isPlatformAndroid) {
      this.http
        .doGetApplicationJsonBearer(
          this.serviziRest.isLiveStreamingAvailableAndroid,
          this.tokenValue
        )
        .subscribe(
          (res) => {
            this.isLiveStreamingDisponibileAndroid = res == 1 ? true : false;
            /*         console.log(this.isLiveStreamingDisponibileAndroid)
                console.log(this.isPlatformAndroid) */
            this.getLiveStreamingData();
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  getLiveStreamingData() {
    if (this.isLiveStreamingDisponibileAndroid && this.isPlatformAndroid) {
      this.http
        .doGetTextBearer(this.serviziRest.getLiveStreamingData, this.tokenValue)
        .subscribe(
          (res) => {
            this.liveStreamingUrlAndroid =
              this.sanitizer.bypassSecurityTrustResourceUrl(res);
          },
          (error) => {
            console.log(error);
          }
        );
    }
    if (this.isLiveStreamingDisponibileiOS && this.isPlatformIos) {
      this.http
        .doGetTextBearer(this.serviziRest.getLiveStreamingM3u, this.tokenValue)
        .subscribe(
          (res) => {
            this.liveStreamingUrlIOS =
              this.sanitizer.bypassSecurityTrustResourceUrl(res);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  getNumeroGgAbbonamenti() {
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.getMaxGgAbbonamento,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.maxGgAbbonamentoPrenotabili = res;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getMaxOmbrelloniPrenotabili() {
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.maxOmbrelloniPrenotabiliPerUtente,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.numMaxOmbrelloniPrenotabili = res;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  capitalizeFirstLetter(string) {
    return (
      string.charAt(0).toUpperCase().trim() +
      string.slice(1).toLowerCase().trim()
    );
  }

  /* Serve a creare una data nel formato AAAA-MM-GG partendo da un Sysdate, per poterlo far leggere alle sezioni contenenti un DATETIME Ionic (es. per prenotazione ombrelloni) */

  creaData() {
    var d = new Date();
    var mese = "" + (d.getMonth() + 1);
    var giorno = "" + d.getDate();
    var anno = d.getFullYear();
    if (mese.length < 2) mese = "0" + mese;
    if (giorno.length < 2) giorno = "0" + giorno;
    return anno + "-" + mese + "-" + giorno;
  }

  creaDataTomorrow(today) {
    let data = today.split("-");
    let anno = data[0];
    let mese = data[1];
    let giorno = data[2];
    let d = new Date(anno, mese, giorno);
    mese = "" + d.getMonth();
    giorno = "" + (d.getDate() + 1);
    anno = d.getFullYear();
    if (mese.length < 2) mese = "0" + mese;
    if (giorno.length < 2) giorno = "0" + giorno;
    return anno + "-" + mese + "-" + giorno;
  }

  async registrazioneUtenteAlert() {
    this.loading = await this.loadingCtrl.create({
      spinner: "bubbles",
      duration: 10000,
      message: this.alertLetturaDatiLabel,
      translucent: true,
    });
    return await this.loading.present();
  }

  async mostraLoader() {
    // console.log('chiamato mostra loader dentro some service');
    this.isLoading = true;
    this.loading = await this.loadingCtrl.create({
      spinner: "bubbles",
      duration: 5000,
      message: this.alertLetturaDatiLabel,
      translucent: true,
    });
    return await this.loading.present();
  }

  async mostraLoaderNoTesti() {
    // console.log('chiamato mostra loader dentro some service');
    this.loading = await this.loadingCtrl.create({
      spinner: "dots",
      duration: 5000,
      message: "",
      translucent: true,
    });
    return await this.loading.present();
  }

  async stoppaLoader() {
    this.isLoading = false;
    if (this.loading != null) {
      await this.loading
        .dismiss()
        .then(() => {
          this.loading = null;
        })
        .catch((e) => console.log(e));
    }
  }

  async invioPagamentoLoader() {
    this.loading = await this.loadingCtrl.create({
      spinner: "bubbles",
      duration: 12500,
      message: this.alertPagamentoInCorsoLabel,
      translucent: true,
    });
    return await this.loading.present();
  }
  async updateDatiLoader() {
    this.loading = await this.loadingCtrl.create({
      spinner: "bubbles",
      duration: 4500,
      message: this.alertSalvataggioInCorsoLabel,
      translucent: true,
    });
    return await this.loading.present();
  }

  logout() {
    if (this.observableVar != undefined) {
      console.log("variabile osservabile");
      this.observableVar.unsubscribe();
    }

    this.storage.set("locale_lang", "");
    this.storage.set("showModale", "");
    this.storage.set("showModaleGenerica", "");
    this.storage.set("dataCookieGenerico", "");
    this.storage.set("token", "");
    this.storage.set("username", "");
  }

  getIsPagamentoPaypal() {
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.isPaypalDisponibile,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.isPaypalDisponibile = res == 1 ? true : false;
          //console.log("isPaypalDisponibile: " + this.isPaypalDisponibile);
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getIsPagamentoContanti() {
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.pagaAncheContanti,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.isPagamentiContantiAbilitato = res == 1 ? true : false;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getIsPagamentoOnline() {
    this.http
      .doGetApplicationJsonBearer(
        this.serviziRest.pagaSoloContanti,
        this.tokenValue
      )
      .subscribe(
        (res) => {
          this.isPagamentiOnlineAbilitato = res == 1 ? true : false;
          //console.log("isPagamentiOnlineAbilitato: " + this.isPagamentiOnlineAbilitato);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  setLingua() {
    this.setLinguaTabs();
    if (this.lingua.languageSelected == "IT") {
      this.disponibilitaLabel = this.lingua.disponibilitaLabel_IT;
      this.appUpdateLabel = this.lingua.appUpdateLabel_IT;
      this.riprovaLabel = this.lingua.riprovaLabel_IT;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_IT;
      this.proseguiGuestLabel = this.lingua.proseguiGuestLabel_IT;
      this.faceLogin = this.lingua.welcome_fbLogin_IT;
      this.registrazione = this.lingua.welcome_registrati_IT;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_IT;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_IT;
      this.alertPagamentoInCorsoLabel = this.lingua.alertPagamentoInCorso_IT;
      this.alertSalvataggioInCorsoLabel =
        this.lingua.alertSalvataggioInCorso_IT;
    } else if (this.lingua.languageSelected == "EN") {
      this.riprovaLabel = this.lingua.riprovaLabel_EN;
      this.disponibilitaLabel = this.lingua.disponibilitaLabel_EN;
      this.appUpdateLabel = this.lingua.appUpdateLabel_EN;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_EN;
      this.proseguiGuestLabel = this.lingua.proseguiGuestLabel_EN;
      this.faceLogin = this.lingua.welcome_fbLogin_EN;
      this.registrazione = this.lingua.welcome_registrati_EN;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_EN;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_EN;
      this.alertUtentePassRiprovareLabel =
        this.lingua.alertUtentePassRiprovare_EN;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_EN;
      this.alertPagamentoInCorsoLabel = this.lingua.alertPagamentoInCorso_EN;
      this.alertSalvataggioInCorsoLabel =
        this.lingua.alertSalvataggioInCorso_EN;
      this.alertErrorLabel = this.lingua.alertError_EN;
    } else if (this.lingua.languageSelected == "FR") {
      this.riprovaLabel = this.lingua.riprovaLabel_FR;
      this.disponibilitaLabel = this.lingua.disponibilitaLabel_FR;
      this.appUpdateLabel = this.lingua.appUpdateLabel_FR;
      this.proseguiGuestLabel = this.lingua.proseguiGuestLabel_FR;
      this.faceLogin = this.lingua.welcome_fbLogin_FR;
      this.registrazione = this.lingua.welcome_registrati_FR;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_FR;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_FR;
      this.alertUtentePassRiprovareLabel =
        this.lingua.alertUtentePassRiprovare_FR;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_FR;
      this.alertPagamentoInCorsoLabel = this.lingua.alertPagamentoInCorso_FR;
      this.alertSalvataggioInCorsoLabel =
        this.lingua.alertSalvataggioInCorso_FR;
    }
  }

  checkSocialLogin() {
    this.isLoginAppleiOSAvailableFunc();
    this.isFbLoginIosAvailableFunc();
    this.isFbLoginAndroidAvailableFunc();
    this.isFbLoginWebAvailableFunc();
    this.isLoginGoogleiOSAvailableFunc();
    this.isLoginGoogleAndroidAvailableFunc();
    this.isLoginGoogleWebAvailableFunc();
  }

  checkPopOver() {
    this.isShowPopOverRistoranteAvailableFunc();
    this.isShowPopOverOmbrelloneAvailableFunc();
  }
  isLoginAppleiOSAvailableFunc() {
    this.http.doGet(serviziRest.isLoginAppleiOSAvailable).subscribe(
      (res) => {
        this.isLoginAppleiOSAvailable = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  isFbLoginIosAvailableFunc() {
    this.http.doGet(serviziRest.isFbLoginIosAvailable).subscribe(
      (res) => {
        this.isLoginFbIosAvailable = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  isFbLoginAndroidAvailableFunc() {
    this.http.doGet(serviziRest.isFbLoginAndroidAvailable).subscribe(
      (res) => {
        this.isLoginFbAndroidAvailable = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  isFbLoginWebAvailableFunc() {
    this.http.doGet(serviziRest.isFbLoginWebAvailable).subscribe(
      (res) => {
        this.isLoginFbWebAvailable = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  isEliminaAccountAvailableFunc() {
    this.http.doGet(serviziRest.isEliminaAccountAvailable).subscribe(
      (res) => {
        this.isEliminaAccountAvailable = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  isLoginGoogleiOSAvailableFunc() {
    this.http.doGet(serviziRest.isLoginGoogleiOSAvailable).subscribe(
      (res) => {
        this.isLoginGoogleiOSAvailable = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  isLoginGoogleAndroidAvailableFunc() {
    this.http.doGet(serviziRest.isLoginGoogleAndroidAvailable).subscribe(
      (res) => {
        this.isLoginGoogleAndroidAvailable = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  isLoginGoogleWebAvailableFunc() {
    this.http.doGet(serviziRest.isLoginGoogleWebAvailable).subscribe(
      (res) => {
        this.isLoginGoogleWebAvailable = res == 1 ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  isShowPopOverRistoranteAvailableFunc() {
    let currentMonth = null;
    this.http
      .doGetTextBearer(serviziRest.getSlotPopOverRistorante, this.tokenValue)
      .subscribe(
        (res) => {
          let mesiCorretti = res.split(",");
          this.http
            .doGet(serviziRest.isShowPopOverRistoranteAvailable)
            .subscribe(
              (res) => {
                let today = new Date();
                currentMonth = today.getMonth() + 1;
                this.showPopOverRistorante =
                  res == 1 && mesiCorretti.includes("" + currentMonth)
                    ? true
                    : false;
              },
              (error) => {
                console.log(error);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );
  }
  isShowPopOverOmbrelloneAvailableFunc() {
    let currentMonth = null;
    this.http
      .doGetTextBearer(serviziRest.getSlotPopOverOmbrellone, this.tokenValue)
      .subscribe(
        (res) => {
          let mesiCorretti = res.split(",");
          this.http
            .doGet(serviziRest.isShowPopOverOmbrelloneAvailable)
            .subscribe(
              (res) => {
                let today = new Date();
                currentMonth = today.getMonth() + 1;
                this.showPopOverOmbrelloni =
                  res == 1 && mesiCorretti.includes("" + currentMonth)
                    ? true
                    : false;
              },
              (error) => {
                console.log(error);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
