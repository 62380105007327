import { Injectable } from '@angular/core';
import { SpiaggiaService } from '../../utils/spiaggia.service';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { RoutingserviceService } from '../../routingservice.service';
import { HttprequestService } from '../../httprequest.service';
import { StorageService } from '../../storage-service.service';
import { AlertService } from '../../alert-service.service';
import { UserService } from '../../utils/user.service';

declare var FB: any;
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class FbloginWebService {


  constructor(
    private myService: SpiaggiaService,
    private http: HttprequestService,
    private storage: StorageService,
    private userService: UserService,
    private alertService: AlertService,
    private routingService: RoutingserviceService

  ) {
    this.initFb();

  }


  initFb() {
    // Load the Facebook API asynchronous when the window starts loading
    window.fbAsyncInit = function () {
      FB.init({
        appId: '1394474224017863',
        status: true,
        xfbml: true,
        version: 'v7.0' // or v2.6, v2.5, v2.4, v2.3
      });

    };


    // Include the Facebook SDK
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/it_IT/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }


  async fbLogin() {

    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
    const result = await <any>FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
    if (result && result.accessToken) {

      this.http.doGet('https://graph.facebook.com/me?fields=name,email,picture.width(200).height(200)&access_token=' + result.accessToken.token).subscribe(response => {
        let data = response;
        this.loginFacebook(result.accessToken, data.email, data.id);
      });
    }

  }

  loginFacebook(res, email, userId) {
    this.myService.mostraLoader();

    let postData = {
      "utente": {
      },
      "credentials": {
        "identifier": userId,
        "secret": res.token
      }

    }

    this.http.doPostApplicationTextAll(this.myService.serviziRest.loginFb, postData)
      .subscribe(res => {

        this.myService.tokenValue = res;
        this.userService.isUserLoggedIn = true;
        this.userService.isUserLoggedInFacebook = true;
        this.storage.set('token', res);
        this.storage.set('username', email);
        this.getDatiUtenteFb(email);
        this.myService.loading.dismiss();
      }, error => {
        this.myService.loading.dismiss();
        if (error.status == 0) {
          this.alertService.alertAddressError();
        } else if (error.status == 400 || error.status == 401 || error.status == 403) {
          this.alertService.alertWrongCredentials();
        } else {
          this.alertService.alertGenericError();
        }
      });

  }

  getDatiUtenteFb(email) {

        this.userService.getDatiUtente(email, 'facebookLogin');

  }


}
