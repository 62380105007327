import { Component } from '@angular/core';
import { LangProvider } from 'src/app/services/lang/lang';
import { SpiaggiaService } from 'src/app/services/utils/spiaggia.service';
import { StorageService } from 'src/app/services/storage-service.service';
import { LoginLabelService } from 'src/app/services/lang/login/login.label.service';
import { AlertService } from 'src/app/services/alert-service.service';
@Component({
  selector: 'fab-buttons-langs',
  templateUrl: './fab-buttons-langs.component.html',
  styleUrls: ['./fab-buttons-langs.component.scss'],
})
export class FabButtonsLangsComponent {


  constructor(
    private storage: StorageService,
    public myService: SpiaggiaService,
    public lingua: LangProvider,
    private loginService: LoginLabelService,
    private alertService: AlertService
  ) {
  }

  chooseLanguage(lingua) {
    let linguaScelta = lingua + "";
    lingua = linguaScelta.toUpperCase();
    this.storage.set('locale_lang', lingua);
    this.lingua.languageSelected = lingua;
    this.myService.setLingua();
    this.loginService.setLingua();
    this.lingua.controllaFlag();
    this.alertService.setLingua();
  }




}

