<ion-fab id="fab-buttons-langs" horizontal="end" vertical="top" slot="fixed" edge>
    <ion-fab-button>
        <span><img style="width: 30px;" class="flag__selected"
                src="assets/imgs/bandiera{{this.lingua.flagLanguageSelected}}.png" /></span>
    </ion-fab-button>
    <ion-fab-list>
        <ion-fab-button (click)="chooseLanguage('It')" color="light">
            <img class="flag__selected" src="assets/imgs/bandieraIt.png" />
        </ion-fab-button>
        <ion-fab-button (click)="chooseLanguage('En')" color="light">
            <img class="flag__selected" src="assets/imgs/bandieraEn.png" />
        </ion-fab-button>
        <ion-fab-button (click)="chooseLanguage('Fr')" color="light">
            <img class="flag__selected" src="assets/imgs/bandieraFr.png" />
        </ion-fab-button>

    </ion-fab-list>
</ion-fab>