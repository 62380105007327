import { Injectable } from '@angular/core';
import { HttprequestService } from '../httprequest.service';
import { serviziRest } from 'src/environments/environment';
import { AlertService } from '../alert-service.service';
import { DatiGlobaliService } from '../utils/dati-globali.service';
import { SpiaggiaService } from './spiaggia.service';
import { LangProvider } from '../lang/lang';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OmbrelloneService {


  carrelloOmbrellone: any[];
  serviziRest: any;
  interval: any[];
  emptyOmbrelloni: boolean = true;
  isCountDownStarted: boolean = false;
  countDownDate: any;
  contaInterval: any;
  elementoOmbrellone: any;
  minDateOmbrelloni: string;
  maxDateOmbrelloni: string;
  preselezioneData = { "dataSceltaInizio": "", "dataSceltaFine": "" };
  minDateOmbrelloniDomani: any;
  isDateErrate: boolean = true;
  isPrenotazioneGiorniMultipli: boolean = false;
  isFasciaOrariaUnica: boolean = true;
  idTurno: number = 1;
  currentPopover: any;
  isGiorniMultipli: boolean = false;
  selezioneInCorso: boolean = false;
/*   lastStoredDataInizio: any; */
  lastStoredDataFine: any;

  constructor(
    private alertService: AlertService,
    private router: Router,
    private http: HttprequestService,
    private utilsOmbrelloniProvider: DatiGlobaliService,
    private lingua: LangProvider,
    private myService: SpiaggiaService,

  ) {
    this.carrelloOmbrellone = [];
    this.serviziRest = serviziRest;
    this.interval = [];
    this.contaInterval = 0;
    this.isCountDownStarted = false;
    this.minDateOmbrelloni = '';
    this.elementoOmbrellone = "";


    this.calcolaInizioStagione();
    this.calcolaFineStagione();





  }

  svuotaCarrelloOmbrellone() { // con questa funzione, elimino gli elementi di tipo Ombrellone dal carrello, allo scadere di un timeout (per ora settato a 10 minuti)
    //  console.log("sto svuotando gli elementi Ombrellone dal carrello per superamento dei minuti di tempo"); 
    for (let i in this.carrelloOmbrellone) {
      if (this.carrelloOmbrellone[i].idOmbrellone != null) {
        //    console.log(this.carrelloOmbrellone[i]);
        this.sbloccaOmbrellone(this.carrelloOmbrellone[i].dataInizio, this.carrelloOmbrellone[i].dataFine, this.carrelloOmbrellone[i].idOmbrellone, 1);
      }
    }

    for (let i in this.interval) {
      clearInterval(this.interval[i]);
    }
    this.alertService.svuotaOmbrelloneAlert();
    this.stopCountdown();
    this.carrelloOmbrellone = [];

    this.emptyOmbrelloni = true;
    const values = ["pagamenti-card", "pagamenti-paypal"];
    if (values.includes(this.myService.provenienzaPage)) {
      this.router.navigate(['/mainpage']);
    }
  }

  sbloccaOmbrellone(dataInizio, dataFine, idOmbrellone, idUser) {


    let postData = {
      "dataInizio": dataInizio,
      "dataFine": dataFine,
      "idOmbrellone": idOmbrellone,
      "idUser": idUser
    };
    this.http.doPostApplicationJsonBearer(this.serviziRest.sbloccaOmbrellone, postData, this.myService.tokenValue)
      .subscribe(() => {

        for (let i in this.interval) {
          clearInterval(this.interval[i]);
        }

      }, error => {

        console.log(error);
      })


  }


  countdown() {
    this.countDownDate = (new Date().getTime()) + 480000; //timeout a 8 minuti
    // this.countDownDate = (new Date().getTime()) + 10000; //timeout a 8 minuti

    this.contaInterval = setInterval(() => {

      this.eseguiSvuotaCarrello();
    }, 1000);

  }

  stopCountdown() {
    var temp = document.getElementById("timer");
    if (temp != null) {
      temp.innerHTML = "";
    }
    clearInterval(this.contaInterval);
    this.isCountDownStarted = false;
  }


  eseguiSvuotaCarrello() {

    // Get todays date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = this.countDownDate - now;
    // Time calculations for days, hours, minutes and d
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    var temp = document.getElementById("timer");
    if (temp != null) {
      temp.innerHTML = minutes + "m " + seconds + "s ";
    }

    // this.timerContatore = minutes + "m " + seconds + "s ";
    // If the count down is over, write some text
    if (distance < 0) {


      clearInterval(this.contaInterval);
      temp = document.getElementById("timer");
      if (temp != null) {
        document.getElementById("timer").innerHTML = "TIMEOUT";
      }
      this.svuotaCarrelloOmbrellone();
      //this.timerContatore = "TIMEOUT";
    }

  }


  async calcolaInizioStagione() {
    this.http.doGetTextPublic(this.serviziRest.calcolaInizioStagione).subscribe((res) => {
      if (res != '' && res != null && res != undefined) {
        this.minDateOmbrelloni = res;
        let dataSplittata: any = res.split('-');
        let anno = dataSplittata[0];
        let mese = dataSplittata[1];
        let giorno = dataSplittata[2];
        dataSplittata = anno + mese + giorno;
        this.calcolaDataMinima(dataSplittata);
      } else {
        this.minDateOmbrelloni = this.myService.creaData();
      }

    });

  }
  calcolaFineStagione() {
    this.http.doGetTextPublic(this.serviziRest.calcolaFineStagione).subscribe((res) => {
      if (res != '' && res != null && res != undefined) {
        this.maxDateOmbrelloni = res;
      } else {
        this.maxDateOmbrelloni = "2022-09-15";
      }
      this.doVerificaDisp();
      this.preselezioneData.dataSceltaInizio = this.minDateOmbrelloni;
      this.preselezioneData.dataSceltaFine = this.preselezioneData.dataSceltaInizio;
      this.minDateOmbrelloniDomani = this.creaDataTomorrow(this.minDateOmbrelloni);


    });

  }


  calcolaDataMinima(dataSplittata) {
    let today = this.myService.creaData();
    let data = today.split('-');
    let anno = data[0];
    let mese = data[1];
    let giorno = data[2];
    today = anno + mese + giorno;
    if (parseInt(today) >= parseInt(dataSplittata)) {
      this.minDateOmbrelloni = this.myService.creaData();
    }
  }



  doVerificaDisp() {


    this.preselezioneData.dataSceltaInizio = this.preselezioneData.dataSceltaInizio.substring(0, 10);
    if (this.isGiorniMultipli == true) {

      this.preselezioneData.dataSceltaFine = this.creaDataTomorrow(this.preselezioneData.dataSceltaInizio);
    } else {
      this.preselezioneData.dataSceltaFine = this.preselezioneData.dataSceltaInizio.substring(0, 10);
    }
    this.isPrenotazioneGiorniMultipli = this.verificaLunghezzaDate();
    this.minDateOmbrelloniDomani = this.creaDataTomorrow(this.preselezioneData.dataSceltaInizio);
  }

  doVerificaDispDataFine() {
    this.preselezioneData.dataSceltaInizio = this.preselezioneData.dataSceltaInizio.substring(0, 10);
    this.preselezioneData.dataSceltaFine = this.preselezioneData.dataSceltaFine.substring(0, 10);
    this.isPrenotazioneGiorniMultipli = this.verificaLunghezzaDate();

  }

  onGiorniMultipliClicked() {
    this.isFasciaOrariaUnica = !this.isGiorniMultipli;
    if (this.isGiorniMultipli == false) {

      this.preselezioneData.dataSceltaFine = this.creaDataTomorrow(this.preselezioneData.dataSceltaInizio);
    } else {
      this.preselezioneData.dataSceltaFine = this.preselezioneData.dataSceltaInizio.substring(0, 10);
    }

    this.idTurno = this.isFasciaOrariaUnica == true ? 1 : this.idTurno;
  //  console.log(this.idTurno);
  }




  riduciData(data) {
    let dataSelezionata = data.replace('-', '');
    dataSelezionata = dataSelezionata.replace('-', '');

    return dataSelezionata;
  }


  verificaLunghezzaDate() {
    let dataRidottaInizio = null;
    let dataRidottaFine = null;
    dataRidottaInizio = this.riduciData(this.preselezioneData.dataSceltaInizio);
    dataRidottaFine = this.riduciData(this.preselezioneData.dataSceltaFine);

    if (dataRidottaFine < dataRidottaInizio) {
      this.isDateErrate = true;
      this.alertService.alertDateErrate();
    } else {

      this.isDateErrate = false;
      if ((dataRidottaFine - dataRidottaInizio) > 0) {
        //  console.log("sono qua dentro verifica date nel true");
        this.isFasciaOrariaUnica = true;
        this.idTurno = 1;
        return true;
      } else {
        this.isFasciaOrariaUnica = false;
        // console.log("sono qua dentro verifica date nel true");
        return false;
      }
    }
  }


  doRicercaOmbrellone() {

    //console.log('ricerco ombrellone');
    /*     if (this.selezioneInCorso) {
          this.sbloccaOmbrellone(this.lastStoredDataInizio, this.lastStoredDataFine, this.myService.idOmbrelloneBloccato, 1);
          this.selezioneInCorso = false;
        } */

    var currentDate = new Date();
    var ora = currentDate.getHours();
    var giorno = currentDate.getDate();
    var mese = currentDate.getMonth();
    var anno = currentDate.getFullYear();
    var giorno_fixed = "";
    var mese_fixed = "";
    if (giorno < 10) {  // serve per il controllo del giorno, per farlo diventare un numero con lo zero davanti
      giorno_fixed = "0";
    }
    giorno_fixed += giorno.toString();
    if (mese < 9) {  // serve per il controllo del giorno, per farlo diventare un numero con lo zero davanti
      mese_fixed = "0";
    }
    mese_fixed += mese + 1;
    var data_fixed = anno + mese_fixed + giorno_fixed;

    var dataSelezionata = this.preselezioneData.dataSceltaInizio.substring(0, 10).replace("-", "");
    dataSelezionata = dataSelezionata.replace("-", "");
    //console.log(this.preselezioneData);

    if (ora >= this.myService.orarioLimitePrenotazioneOmbr && (data_fixed == dataSelezionata) && (this.idTurno != 3)) {
      this.minDateOmbrelloniDomani = this.creaDataTomorrow(this.preselezioneData.dataSceltaInizio);
      this.alertService.alertDopoUnaCertaOra(this.myService.orarioLimitePrenotazioneOmbr, this.myService.isMezzeGiornateDisp);

      if(this.isFasciaOrariaUnica || !this.myService.isMezzeGiornateDisp) {
       this.creaDataGiornoDopo();
      } else {
        this.creaDataPomeriggio();
      }
      

      //  this.verificaLunghezzaDate();
    } else {

      let dataInizioUtc = new Date(this.preselezioneData.dataSceltaInizio);
      let dataFineUtc = new Date(this.preselezioneData.dataSceltaFine);

      let differenzaInOre = dataFineUtc.getTime() - dataInizioUtc.getTime();

      let contaGiorniAbbonamento = (differenzaInOre / (1000 * 3600 * 24)) + 1;

      if (contaGiorniAbbonamento > this.myService.maxGgAbbonamentoPrenotabili) {
        this.alertService.massimoNumGGPrenotabiliErr(this.myService.maxGgAbbonamentoPrenotabili);
      } else {

        if (this.lingua.languageSelected == 'IT') {
          if (this.idTurno == 1) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaInteraGiornataLabel_IT;
          } else if (this.idTurno == 2) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaMattinaLabel_IT;
          } else if (this.idTurno == 3) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaPomeriggioLabel_IT;
          }
        } else if (this.lingua.languageSelected == 'EN') {
          if (this.idTurno == 1) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaInteraGiornataLabel_EN;
          } else if (this.idTurno == 2) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaMattinaLabel_EN;
          } else if (this.idTurno == 3) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaPomeriggioLabel_EN;
          }
        } else if (this.lingua.languageSelected == 'FR') {
          if (this.idTurno == 1) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaInteraGiornataLabel_FR;
          } else if (this.idTurno == 2) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaMattinaLabel_FR;
          } else if (this.idTurno == 3) {
            this.myService.tipoPrenotazione = this.lingua.fasciaOrariaPomeriggioLabel_FR;
          }
        }  


        this.utilsOmbrelloniProvider.idTurno = this.idTurno;
        if (this.myService.provenienzaPage == 'scelta-ombrellone') {
          this.router.navigate(['/new-search-loader']);
        } else {
          this.router.navigate(['/scelta-ombrellone']);
        }
      }
      this.dismissPopover();
    }
  }

  dismissPopover() {
    if (this.currentPopover) {
      this.currentPopover.dismiss().then(() => { this.currentPopover = null; });
    }
  }

  creaDataPomeriggio() {
    this.idTurno = 3; // dopo un certo orario, setta automaticamente il pomeriggio come fascia oraria disponibile
  }

  creaDataGiornoDopo() {
    var d = new Date();
    d.setDate(d.getDate() + 1);
    var mese = '' + (d.getMonth() + 1);
    var giorno = '' + d.getDate();
    var anno = d.getFullYear();
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    this.isDateErrate = false;
    this.preselezioneData.dataSceltaInizio = anno + "-" + mese + "-" + giorno;
    this.minDateOmbrelloni = anno + "-" + mese + "-" + giorno;
    this.preselezioneData.dataSceltaFine = this.preselezioneData.dataSceltaInizio;
    if (this.isGiorniMultipli) {
      this.preselezioneData.dataSceltaFine = this.creaDataTomorrow(this.preselezioneData.dataSceltaInizio)
    }

    return anno + "-" + mese + "-" + giorno;
  }

  creaDataTomorrow(today) {

    let data = today.split('-');
    let anno = data[0];
    let mese = data[1];
    let giorno = data[2];
    let d = new Date(anno, mese - 1, giorno);
    d.setDate(d.getDate() + 1);
    mese = '' + (d.getMonth() + 1);
    giorno = '' + (d.getDate());
    anno = d.getFullYear();
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    return anno + "-" + mese + "-" + giorno;
  }


}
