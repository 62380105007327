<div class="tab__container">

  <div class="display__flex justify__space__evenly flex__direction__row align__items__center width__100">

    <!-- <div (click)="this.routingService.goTo('ecommerce', this.routingService.paginaProvenienza, 'da icona home tab')"
      class="tab__container__item">
      <p>
        E-COMMERCE</p>

    </div> -->
    <div (click)="this.routingService.goTo('mainpage', this.routingService.paginaProvenienza, 'da icona home tab')"
      class="tab__container__item tab__container__item__button">
      <p>
        Home</p>

    </div>

    <div class="tab__container__item tab__container__item__info"
      (click)="this.routingService.goTo('contact', this.routingService.paginaProvenienza, 'da icona informazioni tab')">
      <p style="font-size: 30px;">
        <ion-icon name="information-circle-outline"></ion-icon>
      </p>
    </div>

    <div (click)="this.routingService.login()" *ngIf="this.userService.isUserLoggedIn == false"
      class="tab__container__item tab__container__item__button">
      <p>
        LOGIN</p>
    </div>
    <div (click)="this.routingService.logout()" *ngIf="this.userService.isUserLoggedIn == true"
      class="tab__container__item tab__container__item__button">
      <p>
        LOGOUT</p>
    </div>


  </div>
</div>