import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { LangProvider } from './lang/lang';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alertErroreLabel: string = 'Oops!';
  alertGiorniPassati: string = 'Oops!';
  alertErroreDateLabel: string = '';
  alertAppObsoletaMessLabel: string = '';
  alertPrenotaOmbrellone: string = '';
  alertTempoScadutoLabel: string = '';
  alertAppInAggiornamentoLabel: string = '';
  alertAppInAggiornamentoMessLabel: string = '';
  alertAppObsoletaLabel: string = '';
  alertOmbrNonDisp: string = '';
  alertDopoLeUndiciStart: string = '';
  alertDopoLeUndici: string = '';
  numeroMassimoGgPrenotabiliLabel: string = '';
  numMaxOmbrelloniPrenotabiliLabel: string = '';
  alertDopoLeDodici: string = '';
  alertPrenotaRistorante: string = '';
  alertDopoLeVenti: string = '';
  alertDopoLeUndiciMezzeGiornate: string = '';
  resetPass_modificaLabel: string = '';
  resetPass_msgConfermaLabel: string = '';
  alertPrenotaOmbrelloneLabel = "";
  alertErrorLabel = "";
  alertErrorMessageLabel = "";
  alertUtentePassErratiLabel = "";
  alertUtentePassRiprovareLabel = "";
  alertManutenzione: any;
  alertAppObsoleta: any;
  genericError: any;
  constructor(
    private alertCtrl: AlertController,
    private lingua: LangProvider,
  ) {
    this.setLingua();
  }

  async alertGenericError() {
    if (this.genericError != undefined) {
      this.genericError.dismiss();
    }

    this.genericError = await this.alertCtrl.create({
      header: this.alertErrorLabel,
      message: this.alertErrorMessageLabel,
      buttons: ['OK']
    });

    await this.genericError.present();
  }
  async alertError(message) {
    const alert = await this.alertCtrl.create({
      header: this.alertErrorLabel,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async alertWrongCredentials() {
    const alert = await this.alertCtrl.create({
      header: this.alertUtentePassErratiLabel,
      message: this.alertUtentePassRiprovareLabel,
      buttons: ['OK']
    });

    await alert.present();
  }

  async alertDateErrate() {   // App da aggiornare
    const alert = await this.alertCtrl.create({
      header: this.alertErroreLabel,
      message: this.alertErroreDateLabel,
      buttons: ['OK']
    });

    await alert.present();

  }

  async alertAddressError() {
    const alert = await this.alertCtrl.create({
      header: this.alertErrorLabel,
      message: this.alertErrorMessageLabel,
      buttons: ['OK']
    });

    await alert.present();
  }


  async svuotaOmbrelloneAlert() {
    const alert = await this.alertCtrl.create({
      header: this.alertPrenotaOmbrelloneLabel,
      message: this.alertTempoScadutoLabel,
      buttons: ['OK']
    });

    await alert.present();
  }

  async massimoNumGGPrenotabiliErr(maxGg) {   // App da aggiornare
    const alert = await this.alertCtrl.create({
      header: this.alertErroreLabel,
      message: this.numeroMassimoGgPrenotabiliLabel + ': ' + maxGg,
      buttons: ['OK']
    });
    await alert.present();

  }

  async massimoNumOmbrelloniPrenotabiliErr(maxOmbrelloni) {   // numMaxOmbrelloniPrenotabili
    const alert = await this.alertCtrl.create({
      header: 'Oops!',
      message: this.numMaxOmbrelloniPrenotabiliLabel + maxOmbrelloni,
      buttons: ['OK']
    });
    await alert.present();

  }



  async alertDopoUnaCertaOra(orarioLimite, isMezzeGiornateDisp) {
    // App da aggiornare
    const alert = await this.alertCtrl.create({
      header: this.alertPrenotaOmbrellone,
      message:
        this.alertDopoLeUndiciStart + orarioLimite + (isMezzeGiornateDisp ? this.alertDopoLeUndiciMezzeGiornate : this.alertDopoLeUndici),
      buttons: ["OK"],
    });
    await alert.present();
  }

  async alertGiorniPassatiFunc() {
    const alert = await this.alertCtrl.create({
      header: this.alertErroreLabel,
      message: this.alertGiorniPassati,
      buttons: ['OK']
    });
    await alert.present();
  }

  async erroreDateFunct() {
    const alert = await this.alertCtrl.create({
      header: this.alertErroreLabel,
      message: this.alertErroreDateLabel,
      buttons: ['OK']
    });
    await alert.present();
  }
  async alertTempoScaduto() {
    const alert = await this.alertCtrl.create({
      header: this.alertPrenotaOmbrellone,
      message: this.alertTempoScadutoLabel,
      buttons: ['OK']
    });
    await alert.present();
  }
  async alertOmbrNonDispFunct() {

    const alert = await this.alertCtrl.create({
      header: this.alertPrenotaOmbrellone,
      message: this.alertOmbrNonDisp,
      buttons: ['OK']
    });
    await alert.present();

  }

  setLingua() {
    if (this.lingua.languageSelected == 'IT') {
      this.alertErrorLabel = this.lingua.alertError_IT;
      this.alertPrenotaOmbrelloneLabel = this.lingua.alertPrenotaOmbrellone_IT;
      this.alertErrorMessageLabel = this.lingua.alertErrorMessage_IT;
      this.resetPass_modificaLabel = this.lingua.resetPass_modificaLabel_IT;
      this.resetPass_msgConfermaLabel = this.lingua.resetPass_msgConfermaLabel_IT;
      this.numeroMassimoGgPrenotabiliLabel = this.lingua.numeroMassimoGgPrenotabili_IT;
      this.numMaxOmbrelloniPrenotabiliLabel = this.lingua.numMaxOmbrelloniPrenotabiliLabel_IT;
      this.alertPrenotaOmbrellone = this.lingua.alertPrenotaOmbrellone_IT;
      this.alertGiorniPassati = this.lingua.alertGiorniPassatiLabel_IT;
      this.alertOmbrNonDisp = this.lingua.alertOmbrNonDispLabel_IT;
      this.alertDopoLeUndici = this.lingua.alertDopoLeUndiciLabel_IT;
      this.alertDopoLeUndiciMezzeGiornate = this.lingua.alertDopoLeUndiciMezzeGiornateLabel_IT;
      this.alertDopoLeUndiciStart = this.lingua.alertDopoLeUndiciLabelStart_IT;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_IT;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_IT;
      this.alertUtentePassRiprovareLabel = this.lingua.alertUtentePassRiprovare_IT;
      this.alertAppObsoletaLabel = this.lingua.alertAppObsoleta_IT;
      this.alertAppObsoletaMessLabel = this.lingua.alertAppObsoletaMess_IT;
      this.alertAppInAggiornamentoLabel = this.lingua.alertAppInAggiornamento_IT;
      this.alertAppInAggiornamentoMessLabel = this.lingua.alertAppInAggiornamentoMess_IT;
      this.alertDopoLeDodici = this.lingua.alertDopoLeDodiciLabel_IT;
      this.alertDopoLeVenti = this.lingua.alertDopoLeVentiLabel_IT;
      this.alertPrenotaRistorante = this.lingua.alertPrenotaRistorante_IT;
      this.alertErroreDateLabel = this.lingua.alertErroreDateLabel_IT;
      this.alertErroreLabel = this.lingua.alertErroreLabel_IT;
    } else if (this.lingua.languageSelected == 'EN') {
      this.alertPrenotaOmbrelloneLabel = this.lingua.alertPrenotaOmbrellone_EN;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_EN;
      this.alertUtentePassRiprovareLabel = this.lingua.alertUtentePassRiprovare_EN;
      this.alertErrorLabel = this.lingua.alertError_EN;
      this.alertErrorMessageLabel = this.lingua.alertErrorMessage_EN;
      this.resetPass_modificaLabel = this.lingua.resetPass_modificaLabel_EN;
      this.resetPass_msgConfermaLabel = this.lingua.resetPass_msgConfermaLabel_EN;
      this.alertDopoLeDodici = this.lingua.alertDopoLeDodiciLabel_EN;
      this.alertDopoLeVenti = this.lingua.alertDopoLeVentiLabel_EN;
      this.alertPrenotaRistorante = this.lingua.alertPrenotaRistorante_EN;
      this.alertDopoLeUndiciMezzeGiornate = this.lingua.alertDopoLeUndiciMezzeGiornateLabel_EN;
      this.numeroMassimoGgPrenotabiliLabel = this.lingua.numeroMassimoGgPrenotabili_EN;
      this.numMaxOmbrelloniPrenotabiliLabel = this.lingua.numMaxOmbrelloniPrenotabiliLabel_EN;
      this.alertDopoLeUndici = this.lingua.alertDopoLeUndiciLabel_EN;
      this.alertDopoLeUndiciStart = this.lingua.alertDopoLeUndiciLabelStart_EN;
      this.alertPrenotaOmbrellone = this.lingua.alertPrenotaOmbrellone_EN;
      this.alertGiorniPassati = this.lingua.alertGiorniPassatiLabel_EN;
      this.alertOmbrNonDisp = this.lingua.alertOmbrNonDispLabel_EN;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_EN;
      this.alertAppObsoletaLabel = this.lingua.alertAppObsoleta_EN;
      this.alertAppObsoletaMessLabel = this.lingua.alertAppObsoletaMess_EN;
      this.alertAppInAggiornamentoLabel = this.lingua.alertAppInAggiornamento_EN;
      this.alertAppInAggiornamentoMessLabel = this.lingua.alertAppInAggiornamentoMess_EN;
      this.alertErroreDateLabel = this.lingua.alertErroreDateLabel_EN;
      this.alertErroreLabel = this.lingua.alertErroreLabel_EN;
    } else if (this.lingua.languageSelected == 'FR') {
      this.alertPrenotaOmbrelloneLabel = this.lingua.alertPrenotaOmbrellone_FR;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_FR;
      this.alertUtentePassRiprovareLabel = this.lingua.alertUtentePassRiprovare_FR;
      this.alertErrorLabel = this.lingua.alertError_FR;
      this.alertErrorMessageLabel = this.lingua.alertErrorMessage_FR;
      this.resetPass_msgConfermaLabel = this.lingua.resetPass_msgConfermaLabel_FR;
      this.resetPass_modificaLabel = this.lingua.resetPass_modificaLabel_FR;
      this.alertDopoLeDodici = this.lingua.alertDopoLeDodiciLabel_FR;
      this.alertDopoLeVenti = this.lingua.alertDopoLeVentiLabel_FR;
      this.alertPrenotaRistorante = this.lingua.alertPrenotaRistorante_FR;
      this.alertDopoLeUndici = this.lingua.alertDopoLeUndiciLabel_FR;
      this.alertDopoLeUndiciMezzeGiornate = this.lingua.alertDopoLeUndiciMezzeGiornateLabel_FR;
      this.numeroMassimoGgPrenotabiliLabel = this.lingua.numeroMassimoGgPrenotabili_FR;
      this.numMaxOmbrelloniPrenotabiliLabel = this.lingua.numMaxOmbrelloniPrenotabiliLabel_FR;
      this.alertDopoLeUndiciStart = this.lingua.alertDopoLeUndiciLabelStart_FR;
      this.alertPrenotaOmbrellone = this.lingua.alertPrenotaOmbrellone_FR;
      this.alertGiorniPassati = this.lingua.alertGiorniPassatiLabel_FR;
      this.alertOmbrNonDisp = this.lingua.alertOmbrNonDispLabel_FR;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_FR;
      this.alertAppObsoletaLabel = this.lingua.alertAppObsoleta_FR;
      this.alertAppObsoletaMessLabel = this.lingua.alertAppObsoletaMess_FR;
      this.alertAppInAggiornamentoLabel = this.lingua.alertAppInAggiornamento_FR;
      this.alertAppInAggiornamentoMessLabel = this.lingua.alertAppInAggiornamentoMess_FR;
      this.alertErroreDateLabel = this.lingua.alertErroreDateLabel_FR;
      this.alertErroreLabel = this.lingua.alertErroreLabel_FR;
    }
  }

  async alertVersionOutdate() {
    if (this.alertAppObsoleta != undefined) {
      this.alertAppObsoleta.dismiss();
    }

    this.alertAppObsoleta = await this.alertCtrl.create({
      header: this.alertAppObsoletaLabel,
      message: this.alertAppObsoletaMessLabel,
      buttons: ['OK']
    });

    await this.alertAppObsoleta.present();

  }

  async alertAppInManutenzione() {
    if (this.alertManutenzione != undefined) {
      this.alertManutenzione.dismiss();
    }
    this.alertManutenzione = await this.alertCtrl.create({
      header: this.alertAppInAggiornamentoLabel,
      message: this.alertAppInAggiornamentoMessLabel,
      buttons: ['OK']
    });

    await this.alertManutenzione.present();

  }

  async alertDopoLeDodiciFunc() {
    const alert = await this.alertCtrl.create({
      header: this.alertPrenotaRistorante,
      message: this.alertDopoLeDodici,
      buttons: ['OK']
    });

    await alert.present();
  }

  async alertDopoLeVentiFunc() {
    const alert = await this.alertCtrl.create({
      header: this.alertPrenotaRistorante,
      message: this.alertDopoLeVenti,
      buttons: ['OK']
    });

    await alert.present();
  }



  async presentAlertRecuperaPassword() {
    const alert = await this.alertCtrl.create({
      header: this.resetPass_modificaLabel,
      message: this.resetPass_msgConfermaLabel,
      buttons: ['OK']
    });

    await alert.present();
  }
}
