import { Component } from '@angular/core';
import { SpiaggiaService } from '../../services/utils/spiaggia.service';
import { LangProvider } from '../../services/lang/lang';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttprequestService } from 'src/app/services/httprequest.service';
import { emailPattern } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage-service.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { UserService } from 'src/app/services/utils/user.service';
import { OmbrelloneService } from 'src/app/services/utils/ombrellone.service';
import { LoginLabelService } from 'src/app/services/lang/login/login.label.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  type: string;

  constructor(
    private router: Router,
    public myService: SpiaggiaService,
    private storage: StorageService,
    private alertService: AlertService,
    private httpService: HttprequestService,
    private ombrelloneService: OmbrelloneService,
    private userService: UserService,
    public loginLabelService: LoginLabelService,
    public lingua: LangProvider) {
  }


  loginForm: FormGroup;

  userData = { "password": "", "email": "", "nome": "", "cognome": "", "telefono": "" };

  ngOnInit() {
    this.loginLabelService.setLingua();

    this.loginForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]),
      email: new FormControl('', [Validators.required, Validators.pattern(emailPattern)])
    });
  }

  dismissPopover() {
    this.ombrelloneService.dismissPopover();
  }


  showPassword(input: any, icon: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
    icon.name = icon.name === 'eye-outline' ? 'eye-off-outline' : 'eye-outline';
  }

  procediComeOspite() {
    this.router.navigate(['/mainpage']);
  }


  recuperaPassword() {

    this.dismissPopover();
    this.router.navigate(['/recuperapassword']);
  }

  getDatiUtente(email) {

    this.userService.getDatiUtente(email, '');

  }


  loginManuale() {

    this.myService.mostraLoader(); // fa partire il loader che appare a video, finchè non carica i dati/chiama il servizio

    let postData = {
      "utente": {
        "nome": '',
        "cognome": '',
        "email": this.loginForm.value.email,
        "telefono": ''
      },
      "credentials": {
        "secret": this.loginForm.value.password
      }

    }
    this.httpService.doPostApplicationTextAll(this.myService.serviziRest.loginManuale, postData)
      .subscribe(res => {
        this.myService.tokenValue = res;
        this.userService.isUserLoggedIn = true;
/*         this.storage.set('locale_lang', 'IT'); */
        this.storage.set("token", res);
        this.getDatiUtente(this.loginForm.value.email);



        this.dismissPopover();


        this.storage.set('username', this.loginForm.value.email);

        this.myService.stoppaLoader();
      }, error => {
        console.log(error)
        this.myService.stoppaLoader();
        //   console.log(error.status);
        if (error.status == 0) {
          this.alertService.alertAddressError();
        } else if (error.status == 400 || error.status == 401 || error.status == 403) {
          this.alertService.alertWrongCredentials();
        } else {
          this.alertService.alertGenericError();
        }

      });

  }


  signup() {
    this.router.navigate(['/signup']);
  }

  chooseLanguage(lingua) {
    let linguaScelta = lingua + "";
    lingua = linguaScelta.toUpperCase();
    this.storage.set('locale_lang', lingua);
    this.lingua.languageSelected = lingua;
    this.myService.setLingua();
    this.loginLabelService.setLingua();
    this.lingua.controllaFlag();
  }

  
}

