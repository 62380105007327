import { Component, OnInit } from '@angular/core';
import { RoutingserviceService } from 'src/app/services/routingservice.service';
import { UserService } from 'src/app/services/utils/user.service';

@Component({
  selector: 'tab-component',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {

  constructor(
    public routingService: RoutingserviceService,
    public userService: UserService,
  ) { }

  ngOnInit() {}



}
