import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SpiaggiaService } from '../../services/utils/spiaggia.service';
import { LangProvider } from '../../services/lang/lang';
import { LoginComponent } from '../../components/login/login.component';
import { RoutingserviceService } from '../../services/routingservice.service';
import { FbloginService } from '../../services/signin-services/facebook/fblogin.service';
import { GoogleSigninCapacitorService } from '../../services/signin-services/google/google-signin.service';
import { FbloginWebService } from 'src/app/services/signin-services/facebook/fblogin-web.service';
import { OmbrelloneService } from 'src/app/services/utils/ombrellone.service';



@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})

export class WelcomePage {

  constructor(
    public myService: SpiaggiaService,
    private routingService: RoutingserviceService,
    private ombrelloneService: OmbrelloneService,
    private popoverController: PopoverController,
    private fbloginWebService: FbloginWebService,
    private fbloginService: FbloginService,
    private googleSignInService: GoogleSigninCapacitorService,
    public lingua: LangProvider) {
  }

  popover: any;
  proseguiGuestOppureLabel = "";
  fotoFacebook: any;
  token: any;
  appUpdateLabel = "";
  faceLogin = "";
  registrazione = "";
  proseguiGuestLabel = "";
  alertLetturaDatiLabel = "";

  ionViewWillEnter() {
    this.myService.checkSocialLogin();
    this.routingService.paginaPrecedente = this.routingService.paginaProvenienza;
    this.routingService.paginaProvenienza = 'welcome';

  }

  goBackToWelcomePage() {
    console.log(this.routingService.paginaProvenienza);
    this.routingService.goTo('contact', this.routingService.paginaProvenienza, 'da icona informazioni tab');
  }
  fbLogin() {
    this.fbloginService.fbLogin();
  }
  fbLoginWeb() {
    this.fbloginWebService.fbLogin();
  }


  googleSignInWeb() {
    this.googleSignInService.googleSignin();
  }

  procediComeOspite() {
    this.routingService.goTo('mainpage', this.routingService.paginaProvenienza, 'da welcome')
  }



  signup() {
    this.routingService.goTo('signup', this.routingService.paginaProvenienza, 'da icona faccetta')
  }

  async presentPopoverLogin() {
    this.popover = await this.popoverController.create({
      component: LoginComponent,
      cssClass: 'popover-ombrelloni',
      translucent: false,
      showBackdrop: true,
    });
    await this.popover.present();
    this.ombrelloneService.currentPopover = this.popover;
    const { role } = await this.popover.onDidDismiss();
  }



}
