import { Injectable } from '@angular/core';
import { RoutingserviceService } from '../../routingservice.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AlertService } from '../../alert-service.service';
import { UserService } from '../../utils/user.service';
import { environment, serviziRest } from 'src/environments/environment';
import { HttprequestService } from 'src/app/services/httprequest.service';
import { StorageService } from 'src/app/services/storage-service.service';
import { SpiaggiaService } from '../../utils/spiaggia.service';

@Injectable({
  providedIn: 'root'
})

export class GoogleSigninCapacitorService {
  userData: any;
  token: any;
  serviziRest: any = serviziRest;
  private isNative: boolean;
  constructor(
    private routingService: RoutingserviceService,
    private alertService: AlertService,
    private userService: UserService,
    public myService: SpiaggiaService,
    private http: HttprequestService,
    private storage: StorageService,

  ) {
    console.log('inizializzatore google');
    GoogleAuth.initialize({
      clientId: environment.googleAuthClientId,
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
  }

  async googleSignOut() {
    await GoogleAuth.signOut();
  }


  async signInWithGoogle() {
    try {
      // Initialize the plugin


      await this.googleSignin();
      // Use `googleUser.authentication.accessToken` for backend requests or further processing
    } catch (error) {
      console.error('Error with Google login', error);
    }
  }
  async googleSignin(): Promise<any> {

    this.routingService.paginaProvenienza = 'google-signin';
    this.userService.datiUtente.telefono = '';

    try {
      const googleUser = await GoogleAuth.signIn() as any;
      console.log(googleUser);
      //  console.log('my user: ', googleUser);
      this.setUtenteGoogleSignIn(googleUser);
    } catch (error) {
      console.error(error);
      //   this.googleSignOut();
      this.alertService.alertError('Errore Google Signin');
    }

  }

  setUtenteGoogleSignIn(result) {
    //  console.log(result);
    this.userService.idTokenGoogle = result.authentication.idToken;


    this.userService.datiUtente.nome = result.givenName;
    this.userService.datiUtente.cognome = result.familyName;
    this.userService.datiUtente.email = result.email;


    let postData = {
      "utente": {
        "nome": this.userService.datiUtente.nome,
        "cognome": this.userService.datiUtente.cognome,
        "email": this.userService.datiUtente.email,
        "telefono": this.userService.datiUtente.telefono
      },
      "credentials": {
        "credentialType": "",
        "ID": "",
        "userID": "",
        "identifier": "",
        "secret": this.userService.idTokenGoogle,
        "encrypted": false
      }
    }

    this.http.doPostApplicationTextAll(serviziRest.firebaseAccess, postData)
      .subscribe((response => {
        //  console.log(response);
        this.myService.tokenValue = response;
        this.http.doPostApplicationJsonBearer(serviziRest.getUtente, postData, response)
          .subscribe(res => {
            res.stripeToken == 1 ? this.userService.hasStripeToken = true : false;
            this.userService.datiUtente.telefono = res.telefono;
            if (this.userService.datiUtente.nome == null || this.userService.datiUtente.nome == '' ||
              this.userService.datiUtente.cognome == null || this.userService.datiUtente.cognome == '' ||
              this.userService.datiUtente.telefono == null || this.userService.datiUtente.telefono == ''
            ) {
              this.routingService.goTo('signup-completa', 'googleSignIn', 'googleSignIn');
            } else {
              this.storage.set('token', response);
              this.storage.set('username', this.userService.datiUtente.email);
              this.userService.isUserLoggedIn = true;
              this.userService.isUserLoggedInGoogle = true;
              this.routingService.goTo('mainpage', 'googleSignIn', 'googleSignIn');
            }
          });
      }))
  }
}